import { Binder } from "immer-yjs";
import { useCallback } from "react";
import { Document } from "src/types";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";

import { isFunction } from "lodash-es";
import { nestedNoop } from "src/util/noop";
import { EditorSyncState } from "../types";
import { useEditorSyncStateBinder } from "./useEditorSyncStateBinder";
import { useYDoc } from "./useYDoc";

type SelectionUpdater<T> = T | ((old: T) => T);
export type SelectionUpdaterFn<T> = (s: SelectionUpdater<T>) => void;

const useEditorSyncState = <Selection,>(
  binder: Binder<EditorSyncState> | undefined,
  selector: (state: EditorSyncState) => Selection,
  setter: (state: EditorSyncState, val: Selection) => void,
): [Selection, SelectionUpdaterFn<Selection>] => {
  const value = useSyncExternalStoreWithSelector(
    binder?.subscribe ?? nestedNoop,
    binder?.get ?? (() => ({}) as EditorSyncState),
    binder?.get,
    selector,
  ) as Selection;

  const updater = useCallback(
    (newVal: SelectionUpdater<Selection>) => {
      binder?.update((state) => {
        const updated = isFunction(newVal) ? newVal(selector(state)) : newVal;
        setter(state, updated);
      });
    },
    [binder, selector, setter],
  );

  return [value, updater];
};

export const useSyncedData = (doc: Document) => {
  const ydoc = useYDoc(doc);
  const binder = useEditorSyncStateBinder(ydoc, doc);

  const useTitle = () =>
    useEditorSyncState(
      binder,
      (s) => s?.title ?? "",
      (s, newVal) => {
        s.title = newVal;
      },
    );

  const useFields = () =>
    useEditorSyncState(
      binder,
      (s) => s?.fields ?? {},
      (s, newVal) => {
        s.fields = newVal;
      },
    );

  const useFieldValues = () =>
    useEditorSyncState(
      binder,
      (s) => s?.fieldValues ?? {},
      (s, newVal) => {
        s.fieldValues = newVal;
      },
    );

  const useAgents = () =>
    useEditorSyncState(
      binder,
      (s) => s?.agents ?? [],
      (s, newVal) => {
        s.agents = newVal;
      },
    );

  const useSignatories = () =>
    useEditorSyncState(
      binder,
      (s) => s?.signatories ?? [],
      (s, newVal) => {
        s.signatories = newVal;
      },
    );

  const useDocumentFileSchema = () =>
    useEditorSyncState(
      binder,
      (s) => s?.documentFileSchema ?? [],
      (s, newVal) => {
        s.documentFileSchema = newVal;
      },
    );

  return {
    useTitle,
    ydoc,
    useFields,
    useFieldValues,
    useAgents,
    useSignatories,
    useDocumentFileSchema,
  };
};
