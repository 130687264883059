import { Editor, findChildren } from "@tiptap/react";
import { useCallback } from "react";
import { Doc, StampFieldSchema, StampFieldType, VariablePath } from "src/types";
import { v4 as uuidv4 } from "uuid";
import { PositionParam, StampOptions } from "../types";
import {
  coercePositionParamToCoord,
  coercePositionParamToRange,
} from "../utils";
import { useSyncedData } from "./useSyncedData";

const DEFAULT_STAMP_PROPS: Record<StampFieldType, Partial<StampFieldSchema>> = {
  signature: {
    stamp_type: "signature",
    width: 800,
    height: 200,
  },
  signed_date: {
    stamp_type: "signed_date",
    width: 450,
    height: 80,
    font_size: 12,
  },
  text: {
    stamp_type: "text",
    width: 600,
    height: 100,
    font_size: 12,
  },
};

const makeStampSchema = (
  stampType: StampFieldType,
  fieldName: VariablePath,
  props?: Partial<StampFieldSchema>,
): StampFieldSchema => {
  return {
    id: uuidv4(),
    type: "stamp",
    position: [20, 20],
    page: 0,
    width: 100,
    height: 100,

    ...DEFAULT_STAMP_PROPS[stampType],
    ...props,

    stamp_type: stampType,
    field_name: fieldName,
  } as StampFieldSchema;
};

const fieldNameRegex = (fieldName: VariablePath) =>
  new RegExp(`${fieldName}(\\.|$)`);

export const useDocumentMutations = (__editor: Editor | null, doc: Doc) => {
  const { useDocumentFileSchema } = useSyncedData(doc);
  const [documentFileSchema, setDocumentFileSchema] = useDocumentFileSchema();

  const insertVariable = useCallback(
    (
      variablePath: VariablePath,
      stampOptions: StampOptions = {},
      pos?: PositionParam,
    ) => {
      const fieldName = variablePath;
      const page = stampOptions?.page ?? 0;
      const stamp_type = stampOptions?.stamp_type ?? "text";

      if (doc.documentContentType === "editor") {
        if (!__editor) {
          console.warn("Attempted to insert variable without editor");
          return;
        }

        __editor
          .chain()
          .focus()
          .insertVar(`{{${fieldName}}}`, coercePositionParamToRange(pos))
          .run();
      } else {
        const position = coercePositionParamToCoord(pos);

        const newSchema = makeStampSchema(stamp_type, fieldName, {
          page,
          position,
        });

        setDocumentFileSchema((old) => [...old, newSchema]);
      }
    },
    [__editor, doc.documentContentType, setDocumentFileSchema],
  );

  const deleteVariableById = useCallback(
    (variablePath: VariablePath): void => {
      // Avoid deleting a similar field i.e. id = "asdf" => there's another node "asdfasdf"
      const regex = fieldNameRegex(variablePath);

      if (doc.documentContentType === "editor") {
        // Creates a transaction
        __editor?.commands.command(({ tr, commands }) => {
          const items = findChildren(
            tr.doc,
            (node) =>
              node.type.name === "mention-var" && regex.test(node.attrs.id),
          );

          // Reverse the order so that the position doesn't change between deletes.
          items.reverse().forEach((item) => {
            commands.deleteRange({ from: item.pos, to: item.pos + 1 });
          });

          return true;
        });
      } else {
        setDocumentFileSchema((old) =>
          old.filter((field) => regex.test(field.field_name)),
        );
      }
    },
    [__editor, doc.documentContentType, setDocumentFileSchema],
  );

  const countVariableUses = useCallback(
    (variablePath: VariablePath): number => {
      // Avoid deleting a similar field i.e. id = "asdf" => there's another node "asdfasdf"
      const regex = fieldNameRegex(variablePath);

      if (doc.documentContentType === "editor") {
        if (!__editor) {
          return 0;
        }

        return findChildren(
          __editor.state.doc,
          (node) =>
            node.type.name === "mention-var" && regex.test(node.attrs.id),
        ).length;
      } else {
        return documentFileSchema.filter(
          (field) => field.field_name.match(regex)?.length,
        ).length;
      }
    },
    [__editor, doc.documentContentType, documentFileSchema],
  );

  return {
    insertVariable,
    countVariableUses,
    deleteVariableById,
  };
};
