import { HocuspocusProvider } from "@hocuspocus/provider";
import { Editor } from "@tiptap/react";
import React, { useContext, useMemo, useState } from "react";
import { useAuth } from "src/state/AuthContext";
import { Doc } from "src/types";
import { syncServiceUrl } from "src/util/api";
import { useDocumentMutations } from "./context/hooks/useDocumentMutations";
import { useFieldData } from "./context/hooks/useFieldData";
import { useSaveDocument } from "./context/hooks/useSaveDocument";
import { useSyncedData } from "./context/hooks/useSyncedData";
import { EditorContextState } from "./context/types";

const EditorContext = React.createContext<EditorContextState | undefined>(
  undefined,
);

export const useEditorContext = () => {
  const context = useContext(EditorContext);

  if (!context) {
    throw Error("EditorContext is undefined, something is wrong...");
  }

  return context;
};

export const EditorContextProvider: React.FC<{
  children: React.ReactNode;
  doc: Doc;
}> = ({ children, doc }) => {
  const { authToken } = useAuth();
  const [__editor, __setEditor] = useState<Editor | null>(null);
  const [html, setHtml] = useState(() => doc.html);

  const {
    useTitle,
    ydoc,
    useFields,
    useFieldValues,
    useAgents,
    useSignatories,
    useDocumentFileSchema,
  } = useSyncedData(doc);

  const fieldData = useFieldData(useFields, useFieldValues);

  const { isSaving, saveDocument } = useSaveDocument(doc);

  const { insertVariable, deleteVariableById, countVariableUses } =
    useDocumentMutations(__editor, doc);

  const provider = useMemo(
    () =>
      new HocuspocusProvider({
        url: syncServiceUrl(`/collaboration/${doc.publicUid}`),
        name: `document.${doc.publicUid}`,
        token: authToken ?? "",
        document: ydoc,
      }),
    [doc.publicUid, authToken, ydoc],
  );

  return (
    <EditorContext.Provider
      value={{
        baseDocument: doc,
        fieldData,
        insertVariable,
        ydoc,
        readOnly: doc.executed,
        html,
        setHtml,
        __editor,
        __setEditor,
        provider,
        useTitle,
        useFields,
        useFieldValues,
        useAgents,
        useSignatories,
        useDocumentFileSchema,
        saveDocument,
        deleteVariableById,
        countVariableUses,
        isSaving,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
