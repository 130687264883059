import { AxiosRequestConfig } from "axios";

import {
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { cloneDeep, mapValues, noop } from "lodash-es";
import {
  DocumentFieldValue,
  DocumentId,
  FileValue,
  Packet,
  PacketId,
  PacketReference,
  PacketSummary,
  PacketType,
  PacketVersionNumber,
  PendingOperation,
} from "src/types";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import { prepareFileData } from "../file";
import { packetToSummary } from "../models/packets";
import { destringifyFields } from "../stringifyFields";
import { CacheKeyLibrary, entityKeys, updateCache } from "./apiCache";
import { DocumentApiDetail, docKeys } from "./documentsApi";
import {
  ApiHookResult,
  ApiResponse,
  ArchiveQueryConf,
  GetParams,
  PagedQueryConf,
  SearchQueryConf,
  SortableQueryConf,
} from "./types";

//
// Types
//

export type GetPacketsParams = PagedQueryConf &
  SearchQueryConf &
  SortableQueryConf &
  ArchiveQueryConf & {
    mine?: boolean;
    top?: boolean;
    templates?: boolean;
    requireQuery?: boolean;
    type?: PacketType;
    hideCampaign?: boolean;
    onlyCampaign?: boolean;
  };

export type GetPacketParams = GetParams & { version?: number };

export type PacketsApiIndex = ApiResponse<{ packets: PacketSummary[] }>;
export type PacketApiDetail = ApiResponse<{ packet: Packet }>;
export type PacketVersionApiIndex = ApiResponse<{
  versions: PacketReference[];
}>;

//
// Networking
//

const getPackets = async (
  { templates = false, ...params }: GetPacketsParams,
  conf: AxiosRequestConfig,
) => {
  const url = templates ? ApiRoutes.packetTemplates() : ApiRoutes.packets();
  const resp = await api.get<PacketsApiIndex>(url, {
    params: {
      ...params,
      mine: params.mine || undefined,
      only_campaign: params.onlyCampaign || undefined,
      hide_campaign: params.hideCampaign || undefined,
    },
    ...conf,
  });

  return resp.data;
};

const getPacket = async (
  { id, version }: GetPacketParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<PacketApiDetail>(ApiRoutes.packet({ id }), {
    params: {
      ...conf.params,
      version: version ?? conf.params?.version,
    },
    ...conf,
  });

  return resp.data;
};

const getPacketVesions = async (
  { id }: { id: PacketId },
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<PacketVersionApiIndex>(
    ApiRoutes.packetVerions({ id }),
    conf,
  );
  return resp.data;
};

const combinePackets = async ({
  id,
  otherId,
  title,
}: {
  id: PacketId;
  otherId: PacketId;
  title?: string;
}) => {
  const res = await api.post<PacketApiDetail>(
    ApiRoutes.combinePackets({ id, otherId }),
    { title },
  );

  return res.data;
};

const updatePacket = async ({
  id,
  updates,
}: {
  id: PacketId;
  updates: { title?: string };
}) => {
  const res = await api.patch<PacketApiDetail>(
    ApiRoutes.packet({ id }),
    updates,
  );

  return res.data;
};

const removeDocumentFromPacket = async ({
  id,
  documentId,
}: {
  id: PacketId;
  documentId: DocumentId;
}) => {
  const res = await api.delete<PacketApiDetail>(
    ApiRoutes.removeDocumentFromPacket({ id, documentId }),
  );

  return res.data;
};

type CreatePacketFromTemplateProps = {
  templateId: string;
  title: string;
  documents: Record<
    DocumentId,
    {
      title: string;
      fieldValues: Record<string, DocumentFieldValue>;
    }
  >;
};
const createPacketFromTemplate = async ({
  templateId,
  title,
  documents,
}: CreatePacketFromTemplateProps) => {
  const stringifiedDocuments = mapValues(documents, (d) => ({
    ...d,
    fieldValues: d.fieldValues ? JSON.stringify(d.fieldValues) : undefined,
  }));

  const resp = await api.post<PacketApiDetail>(ApiRoutes.packets(), {
    templateId,
    title,
    documents: stringifiedDocuments,
  });

  return resp.data;
};

const manuallyExecutePacket = async ({
  packetId,
  version,
  virtual,
  proof,
}: {
  packetId: PacketId;
  version: PacketVersionNumber;
  virtual?: boolean;
  proof: Record<DocumentId, File | FileValue>;
}) => {
  for (const docId in proof) {
    const f = proof[docId];
    console.log(`${docId}: ${f}`);

    if ((f as File).name) {
      proof[docId] = await prepareFileData(f as File);
    }
  }

  const resp = await api.post<PacketApiDetail>(
    ApiRoutes.executePacket({ id: packetId }),
    { version, virtual, proof },
    { timeout: 60000 },
  );

  return resp.data;
};

const destroyPacket = async ({ id }: { id: PacketId }) => {
  await api.delete(ApiRoutes.packet({ id }));
};

const archivePacket = async ({ id }: { id: PacketId }) => {
  const resp = await api.post<PacketApiDetail>(ApiRoutes.archivePacket({ id }));

  return resp.data;
};

const unarchivePacket = async ({ id }: { id: PacketId }) => {
  const resp = await api.post<PacketApiDetail>(
    ApiRoutes.unarchivePacket({ id }),
  );

  return resp.data;
};

//
// Hooks
//

const PACKETS_BASE_KEY = "packets";
export const PACKETS_KEYS: CacheKeyLibrary = {
  baseKey: PACKETS_BASE_KEY,
  detailKey: "packet",
  collectionKey: "packets",
};

export const packetsKeys = () => {
  const keys = entityKeys<GetPacketsParams>(PACKETS_BASE_KEY);
  return {
    ...keys,
    versions: (id: string) => [...keys.detail(id), "versions"],
  };
};

export const usePackets = (
  params: GetPacketsParams,
  options?: Partial<UseQueryOptions<PacketsApiIndex>>,
): ApiHookResult<PacketsApiIndex> => {
  const disabled = Boolean(params.requireQuery) && !params.q;

  const query = useQuery({
    queryKey: packetsKeys().list(params),
    queryFn: ({ signal }) => getPackets(params, { signal }),
    enabled: !disabled,
    ...options,
  });

  return {
    data: query.data,
    query,
  };
};

export const usePacket = (
  params: GetPacketParams,
): ApiHookResult<PacketApiDetail> => {
  const query = useQuery({
    queryKey: params.id ? packetsKeys().detail(params.id) : [],
    queryFn: ({ signal }) => getPacket(params, { signal }),
    enabled: Boolean(params.id),
    select: (data) => {
      const newData = cloneDeep(data);
      newData.packet.documents = newData.packet.documents.map((d) =>
        destringifyFields(
          d as any,
          "fields",
          "fieldValues",
          "documentFileSchema",
        ),
      );
      return newData;
    },
  });

  // const update = useUpdatePacket(params.id as PacketId);
  // const destroy = useDestroyDocument(params.id);
  // const archive = useArchiveDocument(params.id);
  // const unarchive = useUnarchiveDocument(params.id);

  return { data: query.data, query };
};

export const useCombinePackets = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      otherId,
      title,
    }: {
      id: PacketId;
      otherId: PacketId;
      title?: string;
    }) => combinePackets({ id, otherId, title }),
    onSuccess: (data, { id }) => {
      updatePacketCache(id, data, queryClient);

      data.packet.documents.forEach((doc) =>
        updatePacketSummaryForDocument(doc.publicUid, data.packet, queryClient),
      );
    },
  });
};

export const useRemoveDocumentFromPacket = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeDocumentFromPacket,
    onSuccess: (data, { id, documentId }) => {
      updatePacketCache(id, data, queryClient);

      // TODO: This should actually update, but the network response
      // doesn't return the new packet for the removed document, so...
      queryClient.invalidateQueries({
        queryKey: docKeys.detail(documentId),
        exact: true,
      });

      data.packet.documents.forEach((doc) =>
        updatePacketSummaryForDocument(doc.publicUid, data.packet, queryClient),
      );
    },
  });
};

export const useUpdatePacket = (id: PacketId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updates: { title?: string }) => updatePacket({ id, updates }),
    onSuccess: (data) => {
      updatePacketCache(id, data, queryClient);

      data.packet.documents.forEach((doc) =>
        updatePacketSummaryForDocument(doc.publicUid, data.packet, queryClient),
      );
    },
  });
};

export const useCreatePacketFromTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPacketFromTemplate,
    onSuccess: (data) =>
      updatePacketCache(data.packet.publicUid, data, queryClient),
  });
};

export const useManuallyExecutePacket = () => {
  return useMutation({
    mutationFn: (args: {
      packetId: PacketId;
      version: PacketVersionNumber;
      virtual?: boolean;
      proof: Record<DocumentId, File>;
    }) => manuallyExecutePacket(args),
  });
};

export const useDestroyPacket = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: PacketId }) => destroyPacket({ id }),
    onSuccess: (_data, { id }) => {
      // TODO: Mutate the local cache, don't just invalidate
      queryClient.invalidateQueries({ queryKey: packetsKeys().lists() });
      queryClient.invalidateQueries({ queryKey: packetsKeys().detail(id) });
    },
  });
};

export const useArchivePacket = (id?: PacketId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: { id: PacketId }) => archivePacket(id ? { id } : p),
    onSuccess: (data) => {
      updatePacketCache(data.packet.publicUid, data, queryClient);
    },
  });
};

export const useUnarchivePacket = (id?: PacketId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: { id: PacketId }) => unarchivePacket(id ? { id } : p),
    onSuccess: (data) => {
      updatePacketCache(data.packet.publicUid, data, queryClient);
    },
  });
};

export const usePacketVersions = (
  id: PacketId,
): ApiHookResult<PacketVersionApiIndex> => {
  const query = useQuery({
    queryKey: id ? packetsKeys().versions(id) : [],
    queryFn: ({ signal }) => getPacketVesions({ id }, { signal }),
    enabled: Boolean(id),
  });

  // const update = useUpdateDocument(params.id);
  // const destroy = useDestroyDocument(params.id);
  // const archive = useArchiveDocument(params.id);
  // const unarchive = useUnarchiveDocument(params.id);

  return { data: query.data, query };
};

export const updatePacketSummaryForDocument = (
  documentId: DocumentId,
  newData: Packet | PacketSummary,
  queryClient: QueryClient,
) => {
  queryClient.setQueriesData(
    { queryKey: docKeys.detail(documentId), exact: true },
    (old: DocumentApiDetail | undefined) => {
      if (!old) {
        return old;
      }

      const newDetail = cloneDeep(old);

      newDetail.document.packet = packetToSummary(newData);
      return newDetail;
    },
  );
};

export const updatePacketCache = (
  id: PacketId,
  newData: PacketApiDetail,
  queryClient: QueryClient,
) =>
  updateCache<
    PendingOperation, // no packet operations... yet,
    PacketApiDetail,
    PacketsApiIndex,
    Packet,
    PacketSummary
  >(
    id,
    newData.packet,
    (d) => d.publicUid,
    () => [],
    noop,
    queryClient,
    PACKETS_KEYS,
  );
