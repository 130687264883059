import { Range } from "@tiptap/react";
import {
  includes,
  isArray,
  isEqualWith,
  isNil,
  isNumber,
  isObject,
  omitBy,
  some,
  sortBy,
} from "lodash-es";
import { Coord, DocumentField, FieldName, Fields } from "src/types";
import { PositionParam } from "./types";

export const undefinedEqualsNotPresentComparison = (
  a: any[] | Record<any, any>,
  b: any[] | Record<any, any>,
) => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  if (!includes(a, undefined) && !includes(b, undefined)) return;

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, (value) => value === undefined),
    omitBy(b, (value) => value === undefined),
    undefinedEqualsNotPresentComparison,
  );
};

const includesNil = (a: any[] | Record<any, any>) => {
  return some(a, isNil);
};

export const nilEqualsNotPresentComparison = (
  a: any[] | Record<any, any>,
  b: any[] | Record<any, any>,
) => {
  if (isArray(a) || isArray(b)) return;
  if (!isObject(a) || !isObject(b)) return;

  if (!includesNil(a) && !includesNil(b)) return;

  // Call recursively, after filtering all undefined properties
  return isEqualWith(
    omitBy(a, isNil),
    omitBy(b, isNil),
    nilEqualsNotPresentComparison,
  );
};

export const sortFields = (fields: Fields) =>
  sortBy(Object.entries(fields), "[1].order") as [FieldName, DocumentField][];

export const signatoryFieldNames = (fields: Fields) =>
  Object.entries(fields)
    .filter(([, def]) => def.type === "person" && def.is_signatory)
    .map(([key]) => key as FieldName);

export const coercePositionParamToCoord = (
  pos?: PositionParam,
): Coord | undefined => {
  if (!pos) {
    return undefined;
  }

  if (isNumber(pos)) {
    return [pos, pos];
  } else if (pos && Object.prototype.hasOwnProperty.call(pos, "from")) {
    const range = pos as Range;
    return [range.from, range.to];
  }

  return pos as Coord;
};

export const coercePositionParamToRange = (
  pos?: PositionParam,
): Range | undefined => {
  if (!pos) {
    return undefined;
  }

  if (isNumber(pos)) {
    return { from: pos, to: pos };
  } else if (isArray(pos)) {
    return { from: pos[0], to: pos[1] };
  }

  return pos as Range;
};
