import { formatDate, parseDateStrict } from "@ream/ui";
import {
  cloneDeep,
  get,
  keyBy,
  mapValues,
  pick,
  snakeCase,
  some,
  truncate,
} from "lodash-es";
import {
  CalendarIcon,
  LucideIcon,
  PenToolIcon,
  TextCursorInputIcon,
  UserIcon,
} from "lucide-react";
import { useMemo } from "react";
import { useEditorContext } from "src/components/editor/EditorContext";
import { DocumentFieldData } from "src/components/editor/context/types";
import {
  Document,
  DocumentField,
  DocumentFieldType,
  DocumentFieldValue,
  DocumentFileSchema,
  FieldName,
  FieldPath,
  Fields,
  Org,
  PacketFieldName,
  PacketFieldPath,
  PacketVariablePath,
} from "src/types";
import { useOrg } from "../api/orgsApi";
import { exhaustiveGuard, generateToken, snakify } from "../utils";

type SubfieldConfiguration = {
  label: string;
};

type FieldConfiguration = {
  subfields?: Record<string, SubfieldConfiguration>;
};

const FIELD_CONFIGURATION: Record<DocumentFieldType, FieldConfiguration> = {
  date: {},
  text: {},
  person: {
    subfields: {
      full_name: {
        label: "Name",
      },
      first_name: {
        label: "First",
      },
      last_name: {
        label: "Last",
      },
      email: {
        label: "Email",
      },
      company_name: {
        label: "Company Name",
      },
      title: {
        label: "Title",
      },
      phone_number: {
        label: "Phone Number",
      },
      fax_number: {
        label: "Fax Number",
      },
      website: {
        label: "Website",
      },
    },
  },
};

export const fieldNameFromPath = (fieldPath: FieldPath): FieldName => {
  let path = fieldPath;
  if (path.startsWith("fields.")) {
    path = path.substring(7) as FieldPath;
  }
  return path.split(".")[0] as FieldName;
};

export const subfieldFromPath = (fieldPath: FieldPath): string | undefined => {
  let path = fieldPath;
  if (path.startsWith("fields.")) {
    path = path.substring(7) as FieldPath;
  }
  return path.split(".")[1];
};

export const packetFieldPathToPacketFieldName = (
  packetFieldPath: PacketFieldPath | undefined,
): PacketFieldName | undefined => {
  if (!packetFieldPath) {
    return undefined;
  }
  const [docId, fieldName] = packetFieldPath.split(".");
  return [docId, fieldName].join(".") as PacketFieldName;
};

export const packetVariablePathToPacketFieldPath = (
  variablePath: PacketVariablePath | undefined,
): PacketFieldPath | undefined => {
  if (!variablePath) {
    return undefined;
  }
  const [docId, type, ...rest] = variablePath.split(".");

  if (type !== "fields") {
    return undefined;
  }

  return [docId, ...rest].join(".") as PacketFieldPath;
};

export type FieldLabelOptions = {
  hideFieldName?: boolean;
  hideSubfieldName?: boolean;
};
type ILabelForFieldPath = {
  (fieldPath: FieldPath, fields: Fields, options?: FieldLabelOptions): string;
  (
    fieldPath: FieldPath,
    field: DocumentField,
    options?: FieldLabelOptions,
  ): string;
};
export const labelForFieldPath: ILabelForFieldPath = (
  fieldPath: FieldPath,
  arg: Fields | DocumentField,
  options?: FieldLabelOptions,
): string => {
  const name = fieldNameFromPath(fieldPath);
  const f: DocumentField = name in arg ? arg[name] : arg;

  const config = FIELD_CONFIGURATION[f.type];
  const subfieldName = subfieldFromPath(fieldPath);

  if (!subfieldName) {
    return f.label;
  }

  const subfield = config.subfields?.[subfieldName];

  if (!subfield) {
    return f.label;
  }

  if (options?.hideFieldName) {
    return subfield.label;
  }
  if (options?.hideSubfieldName) {
    return f.label;
  }

  return `${f.label} - ${subfield.label}`;
};

export type FieldReplacement = {
  label: string;
  accessor: string;
};

export const getFieldReplacements = (
  field: DocumentField,
): FieldReplacement[] => {
  const config = FIELD_CONFIGURATION[field.type] ?? {};

  const subfieldReplacements = Object.entries(config.subfields ?? {}).map(
    ([key, value]) => {
      return {
        label: `${field.label} - ${value.label}`,
        accessor: key,
      } as FieldReplacement;
    },
  );

  return [
    ...subfieldReplacements,
    {
      label: `${field.label}`,
      accessor: `default`,
    },
  ];
};

export const getValue = (
  id: string,
  fieldValues: Record<string, any>,
): string | React.ReactElement | undefined => {
  const valuePath = fullPathForId(id);

  if (!valuePath) {
    return undefined;
  }

  const res = get(fieldValues, valuePath, undefined);

  return res?.default || res;
};

const getFieldLabel = (id: string, fieldLabels: Record<string, any>) => {
  const valuePath = fullPathForId(id);
  if (!valuePath) {
    return undefined;
  }

  const res = get(fieldLabels, valuePath, undefined);

  return res?.default || res;
};

export const labelsForField = (field: DocumentField) => {
  return mapValues(keyBy(getFieldReplacements(field), "accessor"), "label");
};

export const valueForField = (
  field: DocumentField,
  value: any,
  fieldData: DocumentFieldData,
) => {
  if (!field || !value) {
    return undefined;
  }

  if (field.type == "text") {
    return value as string;
  }

  if (field.type == "date") {
    var parsedVal = parseDateStrict(value);
    if (parsedVal == null) {
      return null;
    }
    return formatDate(parsedVal);
  }

  if (field.type == "person") {
    const { people } = fieldData;
    let person = people?.find((p) => p.publicUid === value);
    if (person) {
      person = cloneDeep(person);

      // @ts-expect-error: we're setting an extra value on person which isn't present on the type
      person.default = person.fullName;

      return snakify(person);
    }
    return undefined;
  }
};

const getFieldValues = (
  doc: Pick<Document, "title">,
  org: Org,
  fields: Record<string, DocumentField>,
  fieldValues: Record<string, DocumentFieldValue>,
  fieldData: DocumentFieldData,
) => {
  return {
    document: pick(doc, ["title"]),
    org: pick(org, ["name"]),
    fields: mapValues(fieldValues, (val, key) =>
      valueForField(fields[key], val, fieldData),
    ),
  };
};

const getFieldLabels = (fields: Record<string, DocumentField>) => {
  return {
    document: {
      title: "Document - Title",
    },
    org: {
      name: "Org - Name",
    },
    fields: mapValues(fields, (f) => labelsForField(f)),
  };
};

const fullPathForId = (id: string): string | undefined => {
  if (id.startsWith("{{") && id.endsWith("}}")) {
    const matches = id.match(/{{(.*)}}/);
    if (!matches) {
      return undefined;
    }

    return matches[1];
  }

  return id;
};

const isLoading = (
  id: string,
  fields: Record<string, DocumentField>,
  fieldValues: Record<string, DocumentFieldValue>,
  fieldData: DocumentFieldData,
): boolean => {
  return isPersonFieldLoading(id, fields, fieldValues, fieldData);
};

const isPersonFieldLoading = (
  id: string,
  fields: Record<string, DocumentField>,
  fieldValues: Record<string, DocumentFieldValue>,
  fieldData: DocumentFieldData,
): boolean => {
  if (!isPersonField(id, fields)) {
    return false;
  }

  const value = getFieldValue(id, fieldValues);
  if (!value) {
    return false;
  }

  return fieldData.people === undefined;
};

const isPersonField = (
  id: string,
  fields: Record<string, DocumentField>,
): boolean => {
  return getFieldType(id, fields) === "person";
};

const isField = (id: string): boolean => {
  const path = fullPathForId(id);
  if (!path) {
    return false;
  }

  const split = path.split(".", 2);

  return split[0] == "fields";
};

const getFieldName = (id: string): string | undefined => {
  if (!isField(id)) {
    return undefined;
  }
  const path = fullPathForId(id);

  const split = path?.split(".", 3) ?? [];
  if (split.length < 2) {
    return undefined;
  }

  return split[1];
};

const getFieldType = (
  id: string,
  fields: Record<string, DocumentField>,
): DocumentFieldType | undefined => {
  const name = getFieldName(id);
  if (!name) {
    return undefined;
  }

  return fields[name]?.type;
};

const getFieldValue = (
  id: string,
  fieldValues: Record<string, DocumentFieldValue>,
): DocumentFieldValue | undefined => {
  const name = getFieldName(id);
  if (!name) {
    return undefined;
  }

  return fieldValues[name];
};

/**
 * Determine if a specific field is being used. Note that this expects to appear inside
 * an editor form with access to useField(`html`)
 */

export const useFieldIsUsed = (id: FieldName) => {
  const { html, useFields, useDocumentFileSchema } = useEditorContext();
  const [schema] = useDocumentFileSchema();
  const [fields] = useFields();

  return useMemo(
    () => fieldIsUsed(id, fields[id], html, schema),
    [id, fields[id], html, schema],
  );
};

/**
 * Determine if a specific field is being used.
 */

export const fieldIsUsed = (
  name: FieldName,
  field: DocumentField | null,
  html: string | null,
  schema: DocumentFileSchema | null,
): boolean => {
  const isSignatory = field?.type === "person" && field?.is_signatory;
  return (
    isSignatory ||
    Boolean(
      (html && fieldUsedInHtml(name, html)) ||
        (schema && fieldUsedInSchema(name, schema)),
    )
  );
};

export const fieldUsedInHtml = (name: string, html: string) => {
  const fieldMatches = /data-id="\s*{?{?fields\.(.+?)(?:\..*?)?}?}?"/g;

  const allMatches = [...html.matchAll(fieldMatches)];

  return allMatches.some((m) => m[1] == name);
};

export const useFieldIsUsedInHtml = (name: string, html: string) => {
  return useMemo(() => fieldUsedInHtml(name, html), [name, html]);
};

export const fieldUsedInSchema = (name: string, schema: DocumentFileSchema) => {
  return (
    schema?.some((s) => s.field_name.split(".").some((p) => p === name)) ?? []
  );
};

export const useFieldIsUsedInSchema = (
  name: string,
  schema: DocumentFileSchema,
) => useMemo(() => fieldUsedInSchema(name, schema), [name, schema]);

/**
 * Get the currently set value for a particular field. Note that this expects to appear inside
 * an editor form with access to useField(`fields`), useField(`fieldValues`), and useEditorContext.
 */
export const useFieldValue = (id: string) => {
  const { useFields, useFieldValues, useTitle, fieldData } = useEditorContext();

  const [title] = useTitle();
  const [fields] = useFields();
  const [fieldValues] = useFieldValues();

  const { data: { org } = { org: undefined } } = useOrg();

  const value = useMemo(() => {
    const replacementValues = getFieldValues(
      { title },
      org!,
      fields,
      fieldValues,
      fieldData,
    );

    return getValue(id, replacementValues);
  }, [id, title, org, fields, fieldValues, fieldData]);

  return value;
};

/**
 * Get the currently set label for a particular field. Note that this expects to appear inside
 * an editor form with access to useField(`fields`).
 */
export const useFieldLabel = (id: string) => {
  const { useFields } = useEditorContext();
  const [fields] = useFields();

  return useMemo(() => {
    const allLabels = getFieldLabels(fields);
    return getFieldLabel(id, allLabels);
  }, [id, fields]);
};

/**
 * Get whether a field is currently loading. Note that this expects to appear inside
 * an editor form with access to useField(`fields`), useField(`fieldValues`), and useEditorContext.
 */
export const useFieldLoading = (id: string) => {
  const { useFields, useFieldValues, fieldData } = useEditorContext();
  const [fields] = useFields();
  const [fieldValues] = useFieldValues();

  return useMemo(
    () => isLoading(id, fields, fieldValues, fieldData),
    [id, fields, fieldValues, fieldData],
  );
};

/**
 * Return an Icon for a given field
 */
export const iconForField = (field: DocumentField): LucideIcon => {
  const { type } = field;

  switch (type) {
    case "text":
      return TextCursorInputIcon;
    case "date":
      return CalendarIcon;
    case "person":
      if (field.is_signatory) {
        return PenToolIcon;
      }

      return UserIcon;
    default:
      return exhaustiveGuard(type);
  }
};

export const isDuplicateLabel = (
  fields: Fields,
  label: string,
  fieldName?: FieldName,
) => {
  return some(fields, (field, fn) => {
    return (
      fn != fieldName && field.label.toLowerCase() === label?.toLowerCase()
    );
  });
};

export const generateFieldNameForLabel = (label: string): FieldName => {
  if (!label) {
    return "" as FieldName;
  }

  return truncate(`${generateFieldPrefix()}_${snakeCase(label)}`, {
    length: 80,
    omission: "",
  }) as FieldName;
};

const generateFieldPrefix = () => generateToken({ prefix: "f" });

export const isSignatory = (field: DocumentField) =>
  field.type === "person" && field.is_signatory;
export const isPerson = (field: DocumentField) =>
  field.type === "person" && !field.is_signatory;
