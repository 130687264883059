import { Binder } from "immer-yjs/src";
import { Document } from "src/types";
import { EditorSyncState } from "../types";

import { bind } from "immer-yjs";
import { useEffect, useState } from "react";
import * as Y from "yjs";

export const useEditorSyncStateBinder = (yDoc: Y.Doc, doc: Document) => {
  const [binder, setBinder] = useState<Binder<EditorSyncState> | undefined>(
    undefined,
  );

  useEffect(() => {
    const localBinder = bind<EditorSyncState>(yDoc.getMap("data"));
    setBinder(localBinder);

    return () => {
      localBinder.unbind();
      setBinder(undefined);
    };
  }, [yDoc, doc]);

  return binder;
};
