import { useQueryClient } from "@tanstack/react-query";
import { assign, pick } from "lodash-es";
import { useCallback } from "react";
import { Doc } from "src/types";
import { docReviewKeys } from "src/util/api/documentReviewsApi";
import {
  DocumentPublishTarget,
  docKeys,
  useUpdateDocument,
} from "src/util/api/documentsApi";
import { UpdateDocumentPayload } from "../types";

const buildUpdateData = (
  doc: Doc,
  newData: UpdateDocumentPayload,
  publishTo?: DocumentPublishTarget,
) => {
  // Pluck the simple values
  let existingData: UpdateDocumentPayload = pick(doc, [
    "fields",
    "fieldValues",
    "html",
    "title",
  ]);

  // Set the document content type if we already had one
  if (doc.documentContentType === "file") {
    existingData.documentFileSchema = doc.documentFileSchema;
  }

  // Construct the ID arrays
  existingData.signatories = doc.signatories.map((s) => s.publicUid);
  existingData.agents = doc.agents.map((a) => a.publicUid);

  // Merge everything together
  return assign(
    { base_version: doc.version, publishTo },
    existingData,
    newData,
  );
};

export const useSaveDocument = (doc: Doc) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateDocument, isPending: isSaving } =
    useUpdateDocument(doc.publicUid);

  const saveDocument = useCallback(
    async (data: UpdateDocumentPayload, publish?: DocumentPublishTarget) => {
      const updateData = buildUpdateData(doc, data, publish);

      await updateDocument(
        {
          id: doc.publicUid,
          data: updateData as any,
        },

        {
          onSuccess: (resp) => {
            queryClient.setQueryData(docKeys.detail(doc.publicUid), resp);

            // TODO: This should be more granular
            if (publish) {
              queryClient.invalidateQueries({
                queryKey: docReviewKeys().lists(),
              });

              doc.reviews.forEach((rev) => {
                queryClient.invalidateQueries({
                  queryKey: docReviewKeys().detail(rev.publicUid),
                });
              });
            }
          },
        },
      );
    },
    [doc, updateDocument, queryClient],
  );

  return {
    isSaving,
    saveDocument,
  };
};
