type FieldStringified<T, K extends keyof T> = {
  [KK in keyof T]: KK extends K ? string : T[KK];
};

export const stringifyFields = <T, K extends keyof T>(
  item: T,
  ...keys: K[]
): FieldStringified<T, K> => {
  const overrides = Object.fromEntries(
    keys.map((key) => [key, JSON.stringify(item[key])]),

    // Eslint is wrong, types are not unused variables
    // eslint-disable-next-line unused-imports/no-unused-vars
  ) as { [k in K]: string };

  return Object.assign({}, item, overrides) as FieldStringified<T, K>;
};

export const destringifyFields = <T, K extends keyof T>(
  item: FieldStringified<T, K>,
  ...keys: K[]
): T => {
  const overrides = Object.fromEntries(
    keys.map((key) => {
      let val: any = item[key];
      if (val) {
        try {
          val = JSON.parse(val as any);
        } catch (e) {
          console.warn(`Failed to parse json field ${String(key)} from`, item);
        }
      }

      return [key, val];
    }),

    // Eslint is wrong, types are not unused variables
    // eslint-disable-next-line unused-imports/no-unused-vars
  ) as { [k in K]: string };

  return Object.assign({}, item, overrides) as T;
};
