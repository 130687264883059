import { Color } from "@ream/ui/src/types";
import { pick } from "lodash-es";
import {
  FileIcon,
  FileTypeIcon,
  FolderOpenIcon,
  LucideIcon,
} from "lucide-react";
import { useMemo } from "react";
import {
  DocumentVersion,
  FieldName,
  FieldPath,
  Packet,
  PacketField,
  PacketFieldPath,
  PacketReference,
  PacketSummary,
  PacketVersion,
} from "src/types";
import { FieldLabelOptions, labelForFieldPath } from "../data/documentFields";
import { isNotNil } from "../utils";
import { unfilledDocumentPlaceholders } from "./documents";

export const packetTitle = (packet: PacketSummary | PacketReference) => {
  if (isThinPacket(packet)) {
    return packet.documents[0]?.title ?? packet.title;
  }
  return packet.title;
};

export const isThinPacket = (
  packet: PacketSummary | PacketReference | PacketVersion,
) => {
  if (!packet) {
    return false;
  }

  if ("documentVersions" in packet) {
    return packet.documentVersions.length === 1;
  }

  return packet.documents.length === 1;
};

export const packetToSummary = (
  packet: Packet | PacketSummary,
): PacketSummary =>
  pick(packet, [
    "publicUid",
    "createdAt",
    "updatedAt",
    "lastUsedAsTemplateAt",
    "editedAt",
    "version",
    "title",
    "isTemplate",
    "archived",
    "template",
    "org",
    "author",
    "owner",
    "documents",
    "canDestroy",
  ]);

export const createPacketFields = (packet: Packet): PacketField[] => {
  return packet.documents.flatMap((d) => {
    const result: PacketField[] = Object.entries(d.fields ?? {}).map(
      ([key, f]) => ({
        ...f,
        fieldName: key as FieldName,
        document: d.publicUid,
      }),
    );
    return result;
  });
};

export const labelForPacketFieldPath = (
  path: PacketFieldPath,
  packet: Packet,
  options: FieldLabelOptions & { hideDocumentTitle?: boolean } = {},
) => {
  const [docId, ...fieldPath] = path.split(".");
  const doc = packet.documents.find((d) => d.publicUid === docId);

  if (!doc) {
    return "";
  }

  const fieldLabel = labelForFieldPath(
    fieldPath.join(".") as FieldPath,
    doc?.fields,
    options,
  );

  if (isThinPacket(packet)) {
    return fieldLabel;
  }
  if (options.hideDocumentTitle) {
    return fieldLabel;
  }

  return `${doc.title} - ${fieldLabel}`;
};

export const packetFieldLabel = (field: PacketField, packet: Packet) => {
  const thin = isThinPacket(packet);
  if (thin) {
    return field.label;
  }

  const doc = packet.documents.find((d) => d.publicUid == field.document);
  if (!doc) {
    return field.label;
  }

  return `${doc.title} - ${field.label}`;
};

export const packetFieldKey = (field: PacketField) => {
  return `${field.document}.${field.fieldName}`;
};

interface IlatestVersion {
  (param: DocumentVersion[] | undefined): DocumentVersion | null;
  (param: PacketReference[] | undefined): PacketReference | null;
  (
    param: DocumentVersion[] | PacketReference[] | undefined,
  ): DocumentVersion | PacketReference | null;
}

export const findLatestVersion: IlatestVersion = (param) => {
  if (!param) {
    return null;
  }

  const idx = param.findIndex((o: DocumentVersion | PacketReference) => {
    const virtual = "virtual" in o ? Boolean(o.virtual) : false;
    return !virtual;
  });

  if (idx < 0) {
    return null;
  }

  return param[idx];
};

export const unfilledPacketPlaceholders = (packet: Packet) => {
  const documentUnfilled = packet.documents
    .map((doc) => unfilledDocumentPlaceholders(doc))
    .filter(isNotNil);

  if (documentUnfilled.length === 0) return null;

  return documentUnfilled;
};

export const packetIconAndColor = (
  packet: PacketSummary | Packet,
): [LucideIcon, Color] => {
  if (isThinPacket(packet)) {
    if (packet.documents[0].documentContentType === "file") {
      return [FileIcon, "danger"];
    } else {
      return [FileTypeIcon, "info"];
    }
  } else {
    return [FolderOpenIcon, "gray"];
  }
};

export const packetSignatories = (packet: Packet) =>
  packet?.documents.flatMap((d) => d.signatories) ?? [];

export const usePacketSignatories = (packet: Packet) => {
  useMemo(() => packetSignatories(packet), [packet]);
};
