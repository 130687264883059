import { FileValue } from "src/types";

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const prepareMultiFileData = async (
  files: File[],
): Promise<FileValue[]> => Promise.all(files.map((f) => prepareFileData(f)));

export const prepareFileData = async (file: File): Promise<FileValue> => {
  const data = await toBase64(file);

  return {
    filename: file.name,
    contentType: file.type,
    size: file.size,
    data: addNameToDataURL(data, file.name),
  };
};

export const addNameToDataURL = (data: string, name: string): string => {
  return data.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
};

export const humanFileSize = (size: number): string => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    Number((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const mbToByte = (mb) => 1024 * 1024 * mb;

export const cheapFileHash = (f: FileValue): string =>
  `${f.filename}${f.size}${f.contentType}`;
