import { Document } from "src/types";

import { useEffect, useMemo } from "react";
import { IndexeddbPersistence } from "y-indexeddb";
import * as Y from "yjs";

export const useYDoc = ({ publicUid }: Document) => {
  const ydoc = useMemo(() => new Y.Doc(), [publicUid]);

  useEffect(() => {
    new IndexeddbPersistence(`document.${publicUid}`, ydoc);
  }, [publicUid, ydoc]);

  return ydoc;
};
