import { useMemo } from "react";
import { AgentableId, FieldValues, Fields } from "src/types";
import { useOrgPeople } from "src/util/api/orgsApi";
import { DocumentFieldData } from "../types";

export const useFieldData = (
  useFields: () => [Fields, unknown],
  useFieldValues: () => [FieldValues, unknown],
) => {
  const [fields] = useFields();
  const [fieldValues] = useFieldValues();

  const personFields = useMemo(
    () => Object.keys(fields).filter((key) => fields[key].type === "person"),
    [fields],
  );

  const peopleIds = useMemo(
    () =>
      personFields.map((f) => fieldValues[f] as AgentableId).filter((x) => x),
    [personFields, fieldValues],
  );

  const { data: people, queries } = useOrgPeople(peopleIds);
  const isLoading = queries.some((q) => q.isLoading);

  return useMemo<DocumentFieldData>(
    () => ({ people: isLoading ? undefined : people }),
    [isLoading, people],
  );
};
