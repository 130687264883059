import { filter } from "lodash-es";
import { Doc, DocumentField, DocumentFlagType, DocumentId } from "src/types";
import { tokenizedApiUrl } from "../api";
import { ApiRoutes } from "../apiRoutes";

export const isFlaggedVersion = (
  doc: Doc,
  version: number,
  flag: DocumentFlagType,
): boolean => {
  return doc.flags.some((f) => f.versionNumber === version && f.name === flag);
};

export const hasFlaggedVersion = (
  doc: Doc,
  flag: DocumentFlagType,
): boolean => {
  return doc.flags.some((f) => f.name === flag);
};

export const versionsForFlag = (doc: Doc, flag: DocumentFlagType): number[] =>
  doc.flags.filter((f) => f.name === flag).map((f) => f.versionNumber);

export const firstVersionForFlag = (
  doc: Doc,
  flag: DocumentFlagType,
): number | undefined => Math.min(...versionsForFlag(doc, flag));

export const lastVersionForFlag = (
  doc: Doc,
  flag: DocumentFlagType,
): number | undefined => Math.max(...versionsForFlag(doc, flag));

export const isAcceptedVersion = (doc: Doc, version: number): boolean =>
  isFlaggedVersion(doc, version, "accepted");

export const isAgreedVersion = (doc: Doc, version: number): boolean =>
  isFlaggedVersion(doc, version, "agreed");

export const isSignedVersion = (doc: Doc, version: number): boolean =>
  isFlaggedVersion(doc, version, "signed");

export interface DocumentMissingFields {
  readonly publicUid: DocumentId;
  readonly title: string;
  readonly unfilledFields: DocumentField[];
}

export const unfilledDocumentPlaceholders = (
  doc: Doc,
): null | DocumentMissingFields => {
  const unfilledFields = filter(
    doc.fields,
    (_f, fieldName) => doc.fieldValues[fieldName] == null,
  );

  if (unfilledFields.length == 0) {
    return null;
  }

  return {
    publicUid: doc.publicUid,
    title: doc.title,
    unfilledFields,
  };
};

export const isEditorDocument = (doc: Doc): boolean =>
  doc.documentContentType === "editor";

export const isFileBackedDocument = (doc: Doc): boolean =>
  doc.documentContentType === "file";

export const downloadLink = (id: DocumentId, ext: string, version?: number) => {
  return tokenizedApiUrl(ApiRoutes.downloadDocument({ id, ext, version }));
};
