import { AxiosRequestConfig } from "axios";

import {
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { assign, cloneDeep } from "lodash-es";
import { useEffect } from "react";
import {
  AgentableId,
  Doc,
  DocSummary,
  Document,
  DocumentAttachment,
  DocumentEditorVersion,
  DocumentId,
  DocumentPreview,
  DocumentRelationship,
  DocumentReviewType,
  DocumentSummary,
  DocumentVersionNumber,
  FileValue,
  PacketId,
} from "src/types";
import { prepareFileData } from "src/util/file";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import { useEntitySubscription } from "../sockets";
import { destringifyFields, stringifyFields } from "../stringifyFields";
import { CacheKeyLibrary, entityKeys, simpleUpdateCache } from "./apiCache";
import { DocumentTagApiDetail } from "./documentTagsApi";
import { PacketApiDetail, PacketsApiIndex, packetsKeys } from "./packetsApi";
import {
  ApiResponse,
  ArchiveQueryConf,
  CollectionHook,
  CreateParams,
  DestroyParams,
  GetParams,
  PagedQueryConf,
  SearchQueryConf,
  SingleHook,
  SortableQueryConf,
  UpdateParams,
} from "./types";

//
// Types
//
export type GetDocumentsParams = PagedQueryConf &
  SearchQueryConf &
  SortableQueryConf &
  ArchiveQueryConf & {
    mine?: boolean;
    requireQuery?: boolean;
  };
type GetDocumentParams = GetParams & { version?: number };
type CreateDocumentParams = CreateParams<
  Document & { packetId?: PacketId; documentContentFile?: FileValue }
>;
export type DocumentPublishTarget =
  | "all"
  | "approval"
  | "negotiation"
  | "signature";
type UpdateDocumentParams = UpdateParams<
  Document & {
    documentContentFile?: FileValue;
    baseVersion: number;
    contactId: string;
    agents: AgentableId[];
    signatories: AgentableId[];
    publishTo?: DocumentPublishTarget;
  }
>;
type DestroyDocumentParams = DestroyParams;

//
// Networking
//

export type DocumentsApiIndex = ApiResponse<{ documents: DocumentSummary[] }>;
export type DocumentApiDetail = ApiResponse<{ document: Document }>;
export type ChangedDocumentsDetails = ApiResponse<{ documents: Document[] }>;
export type DocumentVersionApiDetail = ApiResponse<{
  documentVersion: DocumentEditorVersion;
}>;
export type DocumentAttachmentsApiIndex = ApiResponse<{
  attachments: DocumentAttachment[];
}>;

const getDocuments = async (
  { ...params }: GetDocumentsParams,
  conf: AxiosRequestConfig,
) => {
  const url = ApiRoutes.documents();
  const resp = await api.get<DocumentsApiIndex>(url, {
    params: {
      ...params,
      mine: params.mine || undefined,
    },
    ...conf,
  });

  return resp.data;
};

const getRecommendedReviewDocuments = async (
  type: DocumentReviewType,
  conf: AxiosRequestConfig,
) => {
  const url = ApiRoutes.documentsRecommendedReview();
  const resp = await api.get<DocumentsApiIndex>(url, {
    params: { type },
    ...conf,
  });

  return resp.data;
};

const getDocumentsForContact = async (
  { contactId, ...params }: GetDocumentsParams & { contactId: string },
  conf: AxiosRequestConfig,
) => {
  const url = ApiRoutes.contactDocuments({ contactId });
  const resp = await api.get<DocumentsApiIndex>(url, {
    params: {
      ...params,
      mine: params.mine || undefined,
    },
    ...conf,
  });

  return resp.data;
};

const getDocumentsForCompany = async (
  { companyId, ...params }: GetDocumentsParams & { companyId: string },
  conf: AxiosRequestConfig,
) => {
  const url = ApiRoutes.companyDocuments({ companyId });
  const resp = await api.get<DocumentsApiIndex>(url, {
    params: {
      ...params,
      mine: params.mine || undefined,
    },
    ...conf,
  });

  return resp.data;
};

const getDocument = async (
  { id, version }: GetDocumentParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<DocumentApiDetail>(ApiRoutes.document({ id }), {
    params: {
      ...conf.params,
      version: version ?? conf.params?.version,
    },
    ...conf,
  });

  return resp.data;
};

const getDocumentVersion = async (
  { id }: GetDocumentParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<DocumentVersionApiDetail>(
    ApiRoutes.documentVersion({ id }),
    conf,
  );

  return resp.data;
};

const createDocument = async ({ data }: CreateDocumentParams) => {
  const { isTemplate = false } = data;
  const title = isTemplate ? "New Template" : "New Document";
  const html = `<h1>${insertion("{{document.title}}")}</h1><p></p>`;

  const resp = await api.post<DocumentApiDetail>(ApiRoutes.documents(), {
    title,
    html,
    ...stringifyFields(data, "fieldValues", "fields"),
  });

  return resp.data;
};

// TODO: This should be centralized with the code that inserts mentions so that if we change the document format (we probably will) we will produce the right templating.
const insertion = (tag: string) =>
  `<span data-type="mention-var" class="token token--variable" data-id="${tag}" data-label="${tag}">${tag}</span>`;

const updateDocument = async ({ id, data }: UpdateDocumentParams) => {
  const resp = await api.patch<DocumentApiDetail>(
    ApiRoutes.document({ id }),
    stringifyFields(data, "fieldValues", "fields", "documentFileSchema"),
  );

  return resp.data;
};

type SignatoryParams = { id: string; signatoryId: string };
const addSignatory = async ({ id, signatoryId }: SignatoryParams) => {
  const resp = await api.post<DocumentApiDetail>(
    `/api/documents/${id}/signatories`,
    { signatoryId },
  );

  return resp.data;
};

const removeSignatory = async ({ id, signatoryId }: SignatoryParams) => {
  const resp = await api.delete<DocumentApiDetail>(
    `/api/documents/${id}/signatories/${signatoryId}`,
  );

  return resp.data;
};

type AgentParams = { id: string; agentId: string };
const addAgent = async ({ id, agentId }: AgentParams) => {
  const resp = await api.post<DocumentApiDetail>(
    `/api/documents/${id}/agents`,
    { agentId },
  );

  return resp.data;
};

const removeAgent = async ({ id, agentId }: AgentParams) => {
  const resp = await api.delete<DocumentApiDetail>(
    `/api/documents/${id}/agents/${agentId}`,
  );

  return resp.data;
};

type RelatedDocumentParams = {
  id: DocumentId;
  relationship: DocumentRelationship;
  other_document_id: DocumentId;
};
const addRelatedDocument = async ({
  id,
  relationship,
  other_document_id,
}: RelatedDocumentParams) => {
  const resp = await api.post<ChangedDocumentsDetails>(
    `/api/documents/${id}/relationships/${relationship}`,
    { other_document_id },
  );

  return resp.data;
};

const removeRelatedDocument = async ({
  id,
  relationship,
  other_document_id,
}: RelatedDocumentParams) => {
  const resp = await api.delete<ChangedDocumentsDetails>(
    `/api/documents/${id}/relationships/${relationship}/${other_document_id}`,
  );

  return resp.data;
};

const archiveDocument = async ({ id }: DestroyDocumentParams) => {
  const resp = await api.post<DocumentApiDetail>(
    ApiRoutes.archiveDocument({ id }),
  );

  return resp.data;
};

const unarchiveDocument = async ({ id }: DestroyDocumentParams) => {
  const resp = await api.post<DocumentApiDetail>(
    ApiRoutes.unarchiveDocument({ id }),
  );

  return resp.data;
};

type RemoveDraftWatermarkParams = {
  id: DocumentId;
  version: DocumentVersionNumber;
};

const removeDraftWatermark = async ({
  id,
  version,
}: RemoveDraftWatermarkParams) => {
  const resp = await api.delete<DocumentApiDetail>(
    ApiRoutes.removeDraftWatermark({ documentId: id, version }),
  );

  return resp.data;
};

const fetchDocumentPreviewPdf = async ({
  id,
  version,
  preview,
}: {
  id: string;
  version?: number;
  preview?: DocumentPreview;
}) => {
  const params = preview
    ? {
        ...preview,
        version,
        ...stringifyFields(
          preview,
          "fields",
          "fieldValues",
          "documentFileSchema",
        ),
      }
    : { version };

  const resp = await api.post<Blob>(
    ApiRoutes.previewDocumentPdf({ id }),
    params,
    {
      responseType: "blob",
      timeout: 20000,
    },
  );

  return resp.data;
};

const fetchDocumentPdf = async (
  {
    id,
    version,
  }: {
    id: string;
    version?: number;
  },
  conf,
) => {
  const params = { version };
  const resp = await api.get<Blob>(ApiRoutes.previewDocumentPdf({ id }), {
    ...conf,
    params,
    responseType: "blob",
    timeout: 10000,
  });

  return resp.data;
};

const copyDocument = async (id: string) => {
  const resp = await api.post<DocumentApiDetail>(
    ApiRoutes.copyDocument({ id }),
  );

  return resp.data;
};

const convertToTemplate = async (id: string) => {
  const resp = await api.post<DocumentApiDetail>(ApiRoutes.documents(), {
    isTemplate: true,
    documentId: id,
  });

  return resp.data;
};

const addTag = async ({ id, tagId }: { id: string; tagId: string }) => {
  const resp = await api.post<DocumentTagApiDetail>(
    ApiRoutes.documentTags({ id }),
    {
      tagId,
    },
  );

  return resp.data;
};

const removeTag = async ({ id, tagId }) => {
  await api.delete(ApiRoutes.documentTag({ id, tagId }));
};

const destroyAttachment = async (url: string) => {
  await api.delete(url);
};

const uploadAttachment = async ({
  documentId,
  files,
}: {
  documentId: string;
  files: File[];
}) => {
  const attachments = await Promise.all(
    files.map(async (f) => await prepareFileData(f)),
  );

  const resp = await api.post<DocumentAttachmentsApiIndex>(
    ApiRoutes.documentAttachments({ documentId }),
    {
      attachments,
      timeout: 60000,
    },
  );

  return resp.data;
};

const getDocumentAttachment = async (
  attachmentUrl: string,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<Blob>(attachmentUrl, {
    responseType: "blob",
    timeout: 10000,
    ...conf,
  });

  return resp.data;
};

//
// Hooks
//

const DOC_BASE_KEY = "documents";
export const DOC_KEYS: CacheKeyLibrary = {
  baseKey: DOC_BASE_KEY,
  detailKey: "document",
  collectionKey: "documents",
};

const baseDocKeys = entityKeys<GetDocumentsParams>(DOC_BASE_KEY);
export const docKeys = Object.freeze({
  ...baseDocKeys,
  forContactLists: () => [...baseDocKeys.all, "forContact"] as const,
  forContactList: (contactId: string) =>
    [...baseDocKeys.all, "forContact", contactId] as const,
  forCompanyLists: () => [...baseDocKeys.all, "forCompany"] as const,
  forCompanyList: (companyId: string) =>
    [...baseDocKeys.all, "forCompany", companyId] as const,
  downloads: () => [...baseDocKeys.all, "downloads"] as const,
  pdf: (id: string, version?: number) =>
    [...baseDocKeys.all, "pdf", id, version] as const,
  download: (id: string) => [...baseDocKeys.all, "downloads", id] as const,
  downloadExt: (id: string, ext: string) =>
    [...baseDocKeys.all, "downloads", id, ext] as const,
});

export const updateDocCache = (
  id: string,
  newData: Doc | DocSummary,
  queryClient: QueryClient,
) => {
  simpleUpdateCache<DocumentApiDetail, DocumentsApiIndex, Document, DocSummary>(
    id,
    newData,
    (d) => d.publicUid,
    queryClient,
    DOC_KEYS,
  );

  // Update packet detail
  queryClient.setQueriesData(
    { queryKey: packetsKeys().detail(newData.packetPublicUid), exact: true },
    (old: PacketApiDetail | undefined) => {
      if (old && newData) {
        return {
          packet: {
            ...old.packet,
            documents: old.packet.documents.map((d) =>
              d.publicUid === newData.publicUid ? assign({}, d, newData) : d,
            ),
          },
        };
      }
    },
  );

  // Update packet list
  queryClient.setQueriesData(
    { queryKey: packetsKeys().lists(), exact: false },
    (old: PacketsApiIndex | undefined) => {
      if (old && newData) {
        return assign({}, old, {
          packets: old.packets.map((p) => {
            return assign({}, p, {
              documents: p.documents.map((d) =>
                d.publicUid === newData.publicUid ? assign({}, d, newData) : d,
              ),
            });
          }),
        });
      }
    },
  );
};

export const useCreateDocument = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDocument,
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useUpdateDocument = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: UpdateDocumentParams) =>
      updateDocument(id ? { ...p, id } : p),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useDocumentSignatoryActions = (id: string) => {
  const queryClient = useQueryClient();

  const add = useMutation({
    mutationFn: (p: SignatoryParams) => addSignatory({ ...p, id }),
    onSuccess: (newData) => {
      updateDocCache(id, newData.document, queryClient);
    },
  });

  const remove = useMutation({
    mutationFn: (p: SignatoryParams) => removeSignatory({ ...p, id }),
    onSuccess: (newData) => {
      updateDocCache(id, newData.document, queryClient);
    },
  });

  return { add, remove };
};

export const useDocumentAgentActions = (id: string) => {
  const queryClient = useQueryClient();

  const add = useMutation({
    mutationFn: (p: Omit<AgentParams, "id">) => addAgent({ ...p, id }),
    onSuccess: (newData) => {
      updateDocCache(id, newData.document, queryClient);
    },
  });

  const remove = useMutation({
    mutationFn: (p: Omit<AgentParams, "id">) => removeAgent({ ...p, id }),
    onSuccess: (newData) => {
      updateDocCache(id, newData.document, queryClient);
    },
  });

  return { add, remove };
};

export const useDocumentRelationshipActions = (id: DocumentId) => {
  const queryClient = useQueryClient();

  const add = useMutation({
    mutationFn: (p: Omit<RelatedDocumentParams, "id">) =>
      addRelatedDocument({ ...p, id }),
    onSuccess: ({ documents }) => {
      documents.forEach((doc) => {
        updateDocCache(doc.publicUid, doc, queryClient);
      });
    },
  });

  const remove = useMutation({
    mutationFn: (p: Omit<RelatedDocumentParams, "id">) =>
      removeRelatedDocument({ ...p, id }),
    onSuccess: ({ documents }) => {
      documents.forEach((doc) => {
        updateDocCache(doc.publicUid, doc, queryClient);
      });
    },
  });

  return { add, remove };
};

export const useArchiveDocument = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyDocumentParams) => archiveDocument(id ? { id } : p),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useUnarchiveDocument = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyDocumentParams) =>
      unarchiveDocument(id ? { id } : p),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useRemoveDraftWatermark = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: RemoveDraftWatermarkParams) => removeDraftWatermark(p),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useDocumentPreview = (id: string, version?: number) => {
  return useMutation({
    mutationFn: ({ preview }: { preview?: DocumentPreview }) =>
      fetchDocumentPreviewPdf({ id, version, preview }),
    gcTime: 0,
  });
};

export const useDocumentPdf = (id: string, version?: number) => {
  const query = useQuery({
    queryKey: docKeys.pdf(id, version),
    queryFn: ({ signal }) => fetchDocumentPdf({ id, version }, { signal }),
  });

  return {
    data: query.data,
    query,
  };
};

export const useCopyDocument = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => copyDocument(id),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useConvertToTemplate = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => convertToTemplate(id),
    onSuccess: (newData) => {
      updateDocCache(newData.document.publicUid, newData.document, queryClient);
    },
  });
};

export const useDocument = (
  params: GetDocumentParams,
): Omit<SingleHook<Document, DocumentApiDetail>, "destroy"> => {
  const query = useQuery({
    queryKey: params.id ? docKeys.detail(params.id) : [],
    queryFn: ({ signal }) => getDocument(params, { signal }),
    enabled: Boolean(params.id),
    select: (data) => {
      const newData = cloneDeep(data);
      newData.document = destringifyFields(
        newData.document as any,
        "fields",
        "fieldValues",
        "documentFileSchema",
      );
      return newData;
    },
  });

  const update = useUpdateDocument(params.id);
  const archive = useArchiveDocument(params.id);
  const unarchive = useUnarchiveDocument(params.id);

  return { data: query.data, query, update, archive, unarchive };
};

export const useDocuments = (
  params: GetDocumentsParams,
  options?: Partial<UseQueryOptions<DocumentsApiIndex>>,
): CollectionHook<Document, DocumentsApiIndex, DocumentApiDetail> => {
  const disabled = Boolean(params.requireQuery) && !params.q;

  const query = useQuery({
    queryKey: docKeys.list(params),
    queryFn: ({ signal }) => getDocuments(params, { signal }),
    enabled: !disabled,
    ...options,
  });

  const create = useCreateDocument();
  const update = useUpdateDocument();
  const archive = useArchiveDocument();
  const unarchive = useUnarchiveDocument();

  return {
    data: query.data,
    query,
    update,
    create,
    archive,
    unarchive,
  };
};

export const useDocumentRecommendedReview = (type: DocumentReviewType) => {
  const query = useQuery({
    queryKey: ["documents", "recommended", "review"],
    queryFn: ({ signal }) => getRecommendedReviewDocuments(type, { signal }),
  });

  return {
    data: query.data,
    query,
  };
};

export const useDocumentsForContact = (
  contactId: string,
  params: GetDocumentsParams,
) => {
  const query = useQuery({
    queryKey: docKeys.forContactList(contactId),
    queryFn: ({ signal }) =>
      getDocumentsForContact({ contactId, ...params }, { signal }),
  });

  return {
    data: query.data,
    query,
  };
};

export const useDocumentsForCompany = (
  companyId: string,
  params: GetDocumentsParams,
) => {
  const query = useQuery({
    queryKey: docKeys.forCompanyList(companyId),
    queryFn: ({ signal }) =>
      getDocumentsForCompany({ companyId, ...params }, { signal }),
  });

  return {
    data: query.data,
    query,
  };
};

export const useDocumentTagActions = (doc: DocSummary) => {
  const queryClient = useQueryClient();

  const add = useMutation({
    mutationFn: addTag,
    onSuccess: (newTag) => {
      const updatedDocument = assign({}, doc, {
        tags: [...doc.tags, newTag.documentTag],
      });

      updateDocCache(doc.publicUid, updatedDocument, queryClient);
    },
  });

  const remove = useMutation({
    mutationFn: removeTag,
    onSuccess: (_, { tagId }) => {
      const updatedDocument = assign({}, doc, {
        tags: doc.tags.filter((t) => t.publicUid !== tagId),
      });

      updateDocCache(doc.publicUid, updatedDocument, queryClient);
    },
  });

  return { add, remove };
};

export const useUploadDocumentAttachment = (documentId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: { documentId: string; files: File[] }) =>
      p.documentId
        ? uploadAttachment(p)
        : uploadAttachment({ ...p, documentId: documentId!! }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["document_attachment"],
      }),
  });
};

export const useDocumentAttachmentActions = (att: DocumentAttachment) => {
  const download = useQuery({
    queryFn: ({ signal }) => getDocumentAttachment(att.path, { signal }),
    // TODO: Fix query key
    queryKey: ["document_attachment", att.filename],
    enabled: false,
  });

  useEffect(() => {
    if (download.data) {
      saveAs(download.data, att.filename);
    }
  }, [download.data, att.filename]);

  const destroy = useMutation({
    mutationFn: () => destroyAttachment(att.path),
  });

  const upload = useUploadDocumentAttachment();

  return { download, destroy, upload };
};

export const useDocumentVersionPoll = (
  params: GetDocumentParams,
  pollInterval: number = 5000,
) => {
  return useQuery({
    queryFn: ({ signal }) => getDocumentVersion(params, { signal }),
    // TODO: Fix query key
    queryKey: ["document_version", params.id],
    refetchInterval: pollInterval,
    refetchIntervalInBackground: true,
  });
};

export const useDocumentActions = (doc: DocSummary) => {
  const queryClient = useQueryClient();

  const create = useCreateDocument();
  const update = useUpdateDocument(doc.publicUid);
  const archive = useArchiveDocument(doc.publicUid);
  const unarchive = useUnarchiveDocument(doc.publicUid);
  const preview = useDocumentPreview(doc.publicUid);
  const copy = useCopyDocument(doc.publicUid);
  const convertToTemplate = useConvertToTemplate(doc.publicUid);

  const { add: addTag, remove: removeTag } = useDocumentTagActions(doc);

  const uploadAttachment = useUploadDocumentAttachment(doc.publicUid);

  const invalidate = () =>
    queryClient.invalidateQueries({ queryKey: docKeys.lists() });

  return {
    create,
    update,
    archive,
    unarchive,
    preview,
    copy,
    convertToTemplate,
    addTag,
    removeTag,
    uploadAttachment,
    invalidate,
  };
};

export const useDocumentSubscription = (id: string) => {
  const queryClient = useQueryClient();
  const { data: { document: doc } = { document: null } } = useDocument({ id });

  useEntitySubscription("document", id, (req: any) => {
    if (req.version > doc!.version) {
      queryClient.invalidateQueries({ queryKey: docKeys.detail(id) });
    }
  });
};
